import { ref } from 'vue'

export const useToggle = (initialValue = false) => {
  const state = ref(initialValue)

  return {
    state,
    open: () => (state.value = true),
    close: () => (state.value = false),
    toggle: () => (state.value = !state.value),
  }
}
