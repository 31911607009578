import { watch, unref } from 'vue'
import { useMutation, useQueryClient } from 'vue-query'
import { deleteComment } from '@/api/commentApi'

export const useDeleteComment = ({
  isDeleteCommentDialogOpen,
  toggleDeleteCommentDialog,
  selectedComment,
  postId,
}) => {
  const queryClient = useQueryClient()

  const {
    isLoading: deleteCommentLoading,
    isError: deleteCommentError,
    isSuccess: deleteCommentSuccess,
    mutate: initDeleteComment,
    reset: resetDeleteComment,
  } = useMutation(
    'deleteComment',
    selectedComment => {
      return deleteComment(unref(selectedComment).id)
    },
    {
      onSuccess() {
        toggleDeleteCommentDialog()
        queryClient.setQueryData(['comments', postId], comments => {
          return comments.filter(comment => {
            return comment.id !== selectedComment.value.id
          })
        })
      },
    }
  )

  watch(isDeleteCommentDialogOpen, isOpen => {
    if (!isOpen) resetDeleteComment.value()
  })

  return {
    deleteCommentLoading,
    deleteCommentError,
    deleteCommentSuccess,
    initDeleteComment,
  }
}
