<template>
  <div>
    <section>
      <h2 class="text-xl md:text-2xl font-semibold mb-6">Comments</h2>
      <div v-if="fetchCommentsLoading">
        <BaseSpinner />
      </div>
      <div v-else-if="fetchCommentsError">
        <p>There was a problem while loading comments...</p>
      </div>
      <template v-else-if="fetchCommentsSuccess">
        <div v-if="!comments.length">There are no comments yet.</div>
        <ul v-else class="divide-y">
          <li
            v-for="comment of comments"
            :key="comment.id"
            :class="[
              isAdmin && comment.status === 'pending'
                ? 'bg-yellow-50 px-4'
                : '',
            ]"
          >
            <div class="space-y-3 py-4">
              <div class="flex justify-between">
                <h3 class="font-semibold">{{ comment.name }}</h3>
                <div v-if="isAdmin && comment.status === 'pending'">
                  <div
                    class="bg-yellow-100 text-yellow-700 border border-yellow-700 rounded-md px-2 py-1 text-xs"
                  >
                    Pending
                  </div>
                </div>
              </div>
              <div class="flex items-end">
                <p class="flex-grow">{{ comment.comment }}</p>
                <div v-if="isAdmin" class="space-x-3">
                  <button
                    v-if="comment.status === 'pending'"
                    @click.prevent="showApproveCommentDialog(comment)"
                  >
                    <CheckIcon
                      class="w-6 h-6 text-green-600 hover:text-green-900"
                    />
                  </button>
                  <button @click.prevent="showDeleteCommentDialog(comment)">
                    <TrashIcon
                      class="w-6 h-6 text-red-400 hover:text-red-700"
                    />
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </template>
    </section>
    <BaseDialog
      title="Approve comment"
      :show="isApproveCommentDialogOpen"
      :fixed="false"
      enableFocusTrap
    >
      <template #header>
        <header
          class="p-6 font-semibold text-lg bg-green-100 text-green-900 text-center"
        >
          <p>Are you sure you want to approve this comment?</p>
        </header>
        <div v-if="approveCommentError" class="text-red-700 text-center mt-4">
          There was a problem while approving the comment.
        </div>
      </template>
      <template #actions>
        <footer>
          <div class="space-x-3">
            <BaseButton
              class="hover:bg-gray-50"
              @click.prevent="toggleApproveCommentDialog"
              :disabled="approveCommentLoading"
            >
              <template v-if="approveCommentSuccess"> Close </template>
              <template v-else> Cancel </template>
            </BaseButton>
            <BaseButton
              class="bg-green-600 text-green-100 hover:bg-green-700"
              :disabled="approveCommentLoading"
              @click.prevent="initApproveComment(selectedComment)"
              v-if="!approveCommentSuccess"
            >
              <fa-icon
                v-if="approveCommentLoading"
                class="mr-2"
                icon="spinner"
                spin
              />
              <span>Approve</span>
            </BaseButton>
          </div>
        </footer>
      </template>
    </BaseDialog>
    <BaseDialog
      title="Remove comment"
      :show="isDeleteCommentDialogOpen"
      :fixed="false"
      enableFocusTrap
    >
      <template #header>
        <header
          class="p-6 font-semibold text-lg bg-red-100 text-red-900 text-center"
        >
          <p>Are you sure you want to remove this comment?</p>
        </header>
        <div v-if="deleteCommentError" class="text-red-700 text-center mt-4">
          There was a problem while deleting the comment.
        </div>
      </template>
      <template #actions>
        <footer>
          <div class="space-x-3">
            <BaseButton
              class="hover:bg-gray-50"
              :disabled="deleteCommentLoading"
              @click.prevent="toggleDeleteCommentDialog"
            >
              <template v-if="deleteCommentSuccess"> Close </template>
              <template v-else> Cancel </template>
            </BaseButton>
            <BaseButton
              class="bg-red-600 text-red-100 hover:bg-red-700"
              :disabled="deleteCommentLoading"
              @click.prevent="initDeleteComment(selectedComment)"
              v-if="!deleteCommentSuccess"
              >Remove</BaseButton
            >
          </div>
        </footer>
      </template>
    </BaseDialog>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useQuery } from 'vue-query'
import { TrashIcon, CheckIcon } from '@heroicons/vue/outline'
import { fetchComments } from '@/api/commentApi'
import { useToggle } from '@/composables'
import { useApproveComment, useDeleteComment } from './composables'

const store = useStore()

const {
  state: isApproveCommentDialogOpen,
  toggle: toggleApproveCommentDialog,
} = useToggle()

const { state: isDeleteCommentDialogOpen, toggle: toggleDeleteCommentDialog } =
  useToggle()

const isAdmin = computed(() => store.getters.isAdmin)
const props = defineProps({
  post: {
    type: Object,
  },
})
const selectedComment = ref(null)

const showApproveCommentDialog = comment => {
  selectedComment.value = comment
  toggleApproveCommentDialog()
}

const showDeleteCommentDialog = comment => {
  selectedComment.value = comment
  toggleDeleteCommentDialog()
}

const {
  data: allComments,
  isLoading: fetchCommentsLoading,
  isError: fetchCommentsError,
  isSuccess: fetchCommentsSuccess,
} = useQuery(['comments', props.post.id], () => fetchComments(props.post.id))

const comments = computed(() => {
  if (!Array.isArray(allComments.value)) return []
  if (isAdmin.value) return allComments.value
  return allComments.value.filter(comment => comment.status === 'active')
})

const {
  approveCommentLoading,
  approveCommentError,
  approveCommentSuccess,
  initApproveComment,
} = useApproveComment({
  isApproveCommentDialogOpen,
  toggleApproveCommentDialog,
  selectedComment,
  postId: props.post.id,
})

const {
  deleteCommentLoading,
  deleteCommentError,
  deleteCommentSuccess,
  initDeleteComment,
} = useDeleteComment({
  isDeleteCommentDialogOpen,
  toggleDeleteCommentDialog,
  selectedComment,
  postId: props.post.id,
})
</script>

<style lang="scss" module></style>
