import { watch, unref } from 'vue'
import { useMutation, useQueryClient } from 'vue-query'
import { approveComment } from '@/api/commentApi'
export const useApproveComment = ({
  isApproveCommentDialogOpen,
  toggleApproveCommentDialog,
  selectedComment,
  postId,
}) => {
  const queryClient = useQueryClient()
  const {
    isLoading: approveCommentLoading,
    isError: approveCommentError,
    isSuccess: approveCommentSuccess,
    mutate: initApproveComment,
    reset: resetApproveComment,
  } = useMutation(
    'approveComment',
    selectedComment => {
      return approveComment(unref(selectedComment))
    },
    {
      onSuccess() {
        toggleApproveCommentDialog()
        queryClient.setQueryData(['comments', postId], comments => {
          return comments.map(comment => {
            if (comment.id === selectedComment.value.id) {
              return {
                ...comment,
                status: 'active',
              }
            }
            return comment
          })
        })
      },
    }
  )

  watch(isApproveCommentDialogOpen, isOpen => {
    if (!isOpen) resetApproveComment.value()
  })

  return {
    approveCommentLoading,
    approveCommentError,
    approveCommentSuccess,
    initApproveComment,
  }
}
