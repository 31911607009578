<template>
  <div class="flex-grow flex flex-col">
    <section>
      <div class="bg-white overflow-hidden">
        <div class="px-4 py-5">
          <img
            class="mx-auto"
            src="@/assets/homeBanner.jpg"
            alt="A banner image with a photo of Jonathan Powell on the left, the words 'Arete Publishing Ltd' and a mountain range in the background."
          />
        </div>
      </div>
    </section>
    <div class="w-full max-w-7xl mx-auto mb-16 flex-grow">
      <!-- 3 column wrapper -->
      <div class="md:grid md:grid-cols-5 gap-3">
        <!-- main wrapper -->
        <div class="col-span-5 md:col-span-4">
          <div v-if="isLoading">
            <base-spinner></base-spinner>
          </div>
          <div v-else-if="error" class="mt-8 mx-8">
            {{ error }}
          </div>
          <div
            v-else-if="hasPost"
            class="relative px-4 sm:px-6 lg:px-8 flex flex-col"
            style="height: 100%"
          >
            <div class="flex-grow">
              <div
                v-if="isLoggedIn"
                class="admin-controls pt-8 flex justify-end"
              >
                <base-button link :to="editLink" class="ml-3 mr-4 lg:mr-12">
                  <PencilAltIcon
                    class="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  Edit
                </base-button>
              </div>
              <div class="text-lg max-w-prose mx-auto">
                <h1 class="text-5xl">{{ title }}</h1>

                <div class="mt-4 md:mt-6 flex flex-col items-start space-y-3">
                  <p class="text-sm font-medium text-indigo-600">
                    {{ categoryName }}
                  </p>

                  <div>
                    <p class="text-sm font-medium text-gray-900">
                      By {{ author }}
                    </p>
                    <div class="flex space-x-1 text-sm text-gray-500">
                      <time :datePosted="datePosted">
                        {{ dateReadable }}
                      </time>
                      <span aria-hidden="true"> &middot; </span>
                      <span v-if="readingTime"> {{ readingTime }} read </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto"
                v-html="body"
              ></div>
            </div>
            <div class="flex justify-between mt-8 h-full">
              <div>
                <router-link
                  v-if="prevPostUrl"
                  :to="prevPostUrl"
                  class="flex items-center space-x-2"
                >
                  <ChevronDoubleLeftIcon class="w-6 text-gray-500" />
                  <span>Previous Post</span>
                </router-link>
              </div>
              <div>
                <router-link
                  v-if="nextPostUrl"
                  :to="nextPostUrl"
                  class="flex items-center space-x-2"
                >
                  <span>Next Post</span
                  ><ChevronDoubleRightIcon class="w-6 text-gray-500" />
                </router-link>
              </div>
            </div>
          </div>
          <h3 v-else>Post not found.</h3>
        </div>
        <!-- Sidebar -->
        <div class="lg:border-l lg:border-gray-200 p-4">
          <Aside v-if="currentPost" :post="currentPost" />
        </div>
      </div>
    </div>
    <div class="bg-gray-50 mt-8 overflow-hidden" v-if="!isLoading && !error">
      <section
        class="px-6 py-12 md:py-16 space-y-8 max-w-5xl mx-auto transform md:translate-x-[2.75rem]"
      >
        <Comments v-if="currentPost" :post="currentPost" />
        <AddComment :post="currentPost" />
      </section>
    </div>
  </div>
</template>

<script>
import {
  PencilAltIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/vue/solid'
import Aside from './components/Aside'
import Comments from '@/components/blog/comments/Comments.vue'
import AddComment from '@/components/blog/comments/AddComment.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    PencilAltIcon,
    Aside,
    Comments,
    AddComment,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
  },
  props: ['slug'],
  data() {
    return {
      isLoading: false,
      currentPost: null,
      error: false,
    }
  },
  computed: {
    ...mapGetters('blog', {
      allPosts: 'posts',
    }),
    hasPost() {
      return !this.isLoading && this.currentPost !== null
    },
    title() {
      return this.currentPost.title
    },
    categoryName() {
      return this.currentPost.categoryName
    },
    author() {
      return this.currentPost.author
    },
    datePosted() {
      return this.currentPost.datePosted
    },
    readingTime() {
      return this.currentPost.readingTime
    },
    body() {
      return this.currentPost.body
    },
    dateReadable() {
      const date = new Date(this.currentPost.datePosted)
      const dateTimeFormat = new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
      return dateTimeFormat.format(date)
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated
    },
    editLink() {
      return this.$route.path + '/edit'
    },
    prevPostUrl() {
      if (this.currentPostIndex === this.allPosts.length - 1) return

      const nextPost = this.allPosts[this.currentPostIndex + 1]

      return `/blog/${nextPost.slug}`
    },
    nextPostUrl() {
      if (!this.currentPostIndex || this.currentPostIndex === 0) return
      const previousPost = this.allPosts[this.currentPostIndex - 1]
      return `/blog/${previousPost.slug}`
    },
    currentPostIndex() {
      return this.allPosts?.findIndex(post => post.slug === this.slug)
    },
  },
  created() {
    this.loadPost()
  },
  methods: {
    async loadPost(refresh = false) {
      this.isLoading = true
      try {
        await this.$store.dispatch('blog/loadPosts', {
          forceRefresh: refresh,
        })
      } catch (error) {
        this.error =
          error.message || 'Something went wrong: failed to load posts'
        this.isLoading = false
        return
      }
      this.currentPost = this.allPosts?.find(post => post.slug === this.slug)

      // All good, we got the post, so we will shjow it to the user
      if (this.currentPost) {
        this.isLoading = false
        return
      }

      // If a user was redirected from catch-all and
      // there was not post found, we redirect to not-found page
      if (this.$route.query.from === 'catch-all') {
        this.$router.push('/not-found')
        return
      }

      // At this point we should have a blog post, but something went wrong. Maybe it was deleted or is temporarily unavailable.
      this.error = 'Something went wrong. This post might have been removed.'
      this.isLoading = false
    },
  },
}
</script>
