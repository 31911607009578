<template>
  <div class="max-w-md">
    <h3 class="text-xl md:text-2xl font-semibold mb-6">Add Comment</h3>
    <form class="space-y-4 clear-both" @submit.prevent="onAddComment">
      <BaseInput label="Name" v-model="name" :disabled="createCommentLoading" />
      <BaseInput
        label="Comment"
        as="textarea"
        v-model="comment"
        :disabled="createCommentLoading"
      />
      <p class="text-red-700" v-if="error">
        {{ error }}
      </p>
      <p
        v-if="createCommentError"
        class="bg-red-100 text-red-700 p-4 border border-red-700"
      >
        There was a problem while adding your comment. Please try again.
      </p>
      <p
        v-if="createCommentSuccess"
        class="bg-green-100 text-green-700 p-4 border border-green-700"
      >
        {{
          isCommentVisible
            ? 'Comment created.'
            : 'You comment has been submitted for approval.'
        }}
      </p>
      <div class="w-full flex justify-end">
        <BaseButton :disabled="createCommentLoading">
          {{ createCommentLoading ? 'Submitting...' : 'Submit' }}
        </BaseButton>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useMutation, useQueryClient } from 'vue-query'
import { createComment } from '@/api/commentApi'
import { useReCaptcha } from 'vue-recaptcha-v3'
const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

const queryClient = useQueryClient()

const emit = defineEmits(['commentCreated'])

const props = defineProps({
  post: {
    type: Object,
  },
})
const name = ref('')
const comment = ref('')
const error = ref('')
const isCommentVisible = ref(0)

const {
  isLoading: createCommentLoading,
  isSuccess: createCommentSuccess,
  isError: createCommentError,
  mutate: initCreateComment,
} = useMutation(
  async payload => {
    await recaptchaLoaded()
    const recaptchaToken = await executeRecaptcha('submit')

    return createComment({
      ...payload,
      recaptchaToken,
    })
  },
  {
    onSuccess: (data, variables) => {
      const { id, visible } = data
      name.value = ''
      comment.value = ''
      emit('commentCreated', data)
      isCommentVisible.value = visible
      queryClient.setQueryData(['comments', props.post.id], comments => {
        return [
          ...comments,
          {
            ...variables,
            status: visible ? 'active' : 'pending',
            id,
          },
        ]
      })
      queryClient.invalidateQueries(['comments', props.post.id])
    },
  }
)

watch([name, comment], () => {
  error.value = ''
})

const onAddComment = () => {
  if (!name.value || !comment.value) {
    error.value = 'Please fill in all the fields'
    return
  }

  initCreateComment({
    postId: props.post.id,
    name: name.value,
    comment: comment.value,
  })
}
</script>

<style module></style>
