<template>
  <aside v-if="post">
    <h2 class="text-xl md:text-2xl mb-6">Share Post</h2>
    <section
      class="flex md:flex-col justify-between md:space-y-4"
      :class="$style.shareSection"
    >
      <Share
        :class="$style.share"
        network="facebook"
        :url="url"
        :title="post.title"
      >
        <div :class="$style.shareIconContainer">
          <fa-icon
            :class="[$style.shareIcon, $style.fbColor]"
            :icon="['fab', 'facebook-square']"
          />
        </div>
        <span :class="$style.shareText">Facebook</span>
      </Share>
      <Share
        :class="$style.share"
        network="twitter"
        :url="url"
        :title="post.title"
      >
        <div :class="$style.shareIconContainer">
          <fa-icon
            :class="[$style.shareIcon, $style.twitterColor]"
            :icon="['fab', 'twitter-square']"
          />
        </div>
        <span :class="$style.shareText">Twitter</span>
      </Share>
      <Share
        :class="$style.share"
        network="linkedin"
        :url="url"
        :title="post.title"
      >
        <div :class="$style.shareIconContainer">
          <fa-icon
            :class="[$style.shareIcon, $style.linkedInColor]"
            :icon="['fab', 'linkedin']"
          />
        </div>
        <span :class="$style.shareText">LinkedIn</span>
      </Share>
      <Share
        :class="$style.share"
        network="email"
        :url="url"
        :title="post.title"
      >
        <div :class="$style.shareIconContainer">
          <fa-icon
            :class="[$style.shareIcon, 'text-teal-700']"
            :icon="['fas', 'envelope']"
          />
        </div>
        <span :class="$style.shareText">Email</span>
      </Share>
      <button
        v-if="isClipboardSupported"
        :class="[$style.share, $style.copyButton]"
        @click.prevent="() => copy()"
      >
        <div :class="$style.shareIconContainer">
          <fa-icon
            :class="[$style.shareIcon, 'text-warmGray-600']"
            :icon="['fas', 'clipboard']"
          />
        </div>

        <span :class="$style.shareText">
          <template v-if="!copied"> Copy link </template>
          <template v-else> Link copied! </template>
        </span>
      </button>
    </section>
    <section class="mt-8">
      <h2 class="text-xl md:text-2xl mb-4">Press</h2>
      <p class="text-lg text-gray-500 mb-4">
        Get notified when new articles are released!
      </p>
      <Newsletter />
    </section>
    <section class="mt-8">
      <h2 class="text-xl md:text-2xl mb-4">Join Jonathan on</h2>
      <div class="flex md:justify-between space-x-4 md:space-x-0">
        <a
          href="https://twitter.com/PowRaymond"
          target="_blank"
          rel="noreferrer noopener"
        >
          <fa-icon
            :class="[$style.shareIcon, $style.twitterColor]"
            :icon="['fab', 'twitter-square']"
        /></a>
        <a
          href="https://www.facebook.com/arete.publishing"
          target="_blank"
          rel="noreferrer noopener"
        >
          <fa-icon
            :class="[$style.shareIcon, $style.fbColor]"
            :icon="['fab', 'facebook-square']"
          />
        </a>

        <a
          href="https://www.youtube.com/channel/UCh1Bgvr074FlnxIZ9xARfKg
"
          target="_blank"
          rel="noreferrer noopener"
        >
          <fa-icon
            :class="[$style.shareIcon, $style.youtubeColor]"
            :icon="['fab', 'youtube']"
        /></a>
        <a
          href="https://www.linkedin.com/in/jonathan-powell-bb4a731b/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <fa-icon
            :class="[$style.shareIcon, $style.linkedInColor]"
            :icon="['fab', 'linkedin']"
        /></a>
      </div>
    </section>
  </aside>
</template>

<script setup>
import Share from '@/components/share/Share'
import { ref } from 'vue'
import { useClipboard } from '@vueuse/core'
import Newsletter from '@/components/newsletter/NewsletterForm'

defineProps({
  post: {
    type: Object,
  },
})

const url = window.location.href
const source = ref(url)
const {
  copy,
  copied,
  isSupported: isClipboardSupported,
} = useClipboard({ source })
</script>

<style module>
.share {
  @apply space-x-2 flex items-center;
}

.shareSection {
  max-width: 10rem;
  @apply md:max-w-none;
}

.shareIconContainer {
  @apply w-8 h-8;
}

.shareIcon {
  @apply text-3xl w-8 h-8;
}

.shareText {
  @apply hidden md:block;
}

.copyButton {
  @apply hidden md:flex;
}
.fbColor {
  color: #4267b2;
}

.twitterColor {
  color: #1da1f2;
}

.linkedInColor {
  color: #0e76a8;
}

.youtubeColor {
  color: #ff0000;
}
</style>
