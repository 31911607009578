import api from './api'

export const fetchComments = post_id => {
  return api
    .get('comment/read_all.php', {
      params: {
        post_id,
      },
    })
    .then(res =>
      res.data.data.map(item => {
        const { id, name, body: comment, post_id: postId, visible } = item
        return {
          id,
          name,
          comment,
          postId,
          status: visible == 1 ? 'active' : 'pending',
        }
      })
    )
}

export const createComment = payload => {
  const { name, comment, postId, recaptchaToken } = payload
  return api
    .post('comment/create.php', {
      post_id: postId,
      name,
      body: comment,
      recaptchaToken,
    })
    .then(res => res.data)
}

export const approveComment = comment => {
  return api.post('comment/update.php', {
    ...comment,
    body: comment.comment,
    visible: 1,
  })
}

export const deleteComment = id => {
  return api.delete('comment/delete_single.php', {
    data: {
      id,
    },
  })
}
